import { api } from './../api';

export const invites = {
  state: [], // initial state
  reducers: {
    // handle state changes with pure functions
    update(state, payload) {
      return payload;
    },
    merge(state, payload) {
      return [...state, ...payload];
    },
    add(state, payload) {
      return [...state, payload];
    },
    updateOne(state, payload) {
      return state.map(item => {
        if (item.id === payload.id) {
          return payload;
        }

        return item;
      });
    }
  },
  effects: dispatch => ({
    async getAll(payload, rootState) {
      const invites = await api.get('/invites');
      dispatch.invites.update(invites.data);
    },
    async get(payload, rootState) {
      const invite = await api.get('/invites/' + payload.id);
      dispatch.invites.add(invite.data);
    },
    async getOwn(payload, rootState) {
      const invite = await api.get('/me/invites');
      dispatch.invites.add(invite.data);
    },
    async addInvite(payload, rootState) {
      const newInvite = await api.post('/invites', { emails: payload });
      dispatch.invites.merge(newInvite.data.invites);
      return newInvite.data;
    },
    async resendInvite(payload) {
      const invite = await api.post('/invites/' + payload.id);
      dispatch.invites.updateOne(invite.data);
      return invite.data;
    },
    async removeInvite(payload) {
      await api.delete('/invites/' + payload.id);
    }
  })
};
