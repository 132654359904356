/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';

/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';
export const USER_LOADED = 'USER_LOADED';
export const CONFIRM_USER = 'CONFIRM_USER';
export const CONFIRM_USER_STARTED = 'CONFIRM_USER_STARTED';
export const CONFIRM_USER_SUCCESS = 'CONFIRM_USER_SUCCESS';
export const CONFIRM_USER_FAILURE = 'CONFIRM_USER_FAILURE';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_STARTED = 'REGISTER_USER_STARTED';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILURE = 'REGISTER_USER_FAILURE';
export const UPDATE_USER_STARTED = 'UPDATE_USER_STARTED';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const LOGOUT_USER = 'LOGOUT_USER';
export const SESSION_LOAD = 'SESSION_LOAD';
export const CLEAR_ERROR = 'CLEAR_ERROR';

/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES';
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS = 'MENU_CHANGE_HAS_SUB_ITEM_STATUS';

/* STREAMER */
export const SET_STREAMER_STATE_SUCCESS = 'SET_STREAMER_STATE_SUCCESS';
export const SET_STREAMER_STATE_ERROR = 'SET_STREAMER_STATE_ERROR';
export const GET_STREAMER_STATE = 'MENU_CONTAINER_ADD_CLASSNAME';

export * from './menu/actions';
export * from './settings/actions';
export * from './user/actions';
