import React, { createContext, useContext, useState } from 'react';

// Create the context
const PurchaseContext = createContext();

const usePurchaseContext = () => {
  const context = useContext(PurchaseContext);

  if (!context) {
    throw new Error('usePurchaseContext must be used within a PurchaseProvider');
  }

  return context;
};

// Create the provider component
const PurchaseProvider = ({ children }) => {
  const [selectedChallenge, setSelectedChallenge] = useState(null);

  return (
    <PurchaseContext.Provider value={{ selectedChallenge, setSelectedChallenge }}>
      {children}
    </PurchaseContext.Provider>
  );
};

export { PurchaseContext, PurchaseProvider, usePurchaseContext };
