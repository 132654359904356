import { api } from './../api';
import produce from 'immer';

export const company = {
  state: null,
  reducers: {
    setData(state, payload) {
      return payload;
    },
    setLogo(state, { logoPath }) {
      return { ...state, logoPath };
    },
    setData(state, data) {
      return { ...state, ...data };
    }
  },
  effects: dispatch => ({
    async getCurrent() {
      const company = await api.get('/me/company');
      dispatch.company.setData(company.data);
    },
    async updateLogo(payload) {
      const logoUpdated = await api.put('/me/company/logo', {
        logoPath: payload
      });
      dispatch.company.setLogo(logoUpdated.data);
    },
    async updateData(payload) {
      const updatedCompany = await api.put('/me/company', payload);
      dispatch.company.setData(updatedCompany.data);
    }
  })
};
