import { combineReducers } from "redux";
import settings from "./settings/reducer";
import menu from "./menu/reducer";
import auth from "./auth/reducers";
import authUser from "./user/reducers";
import streamer from "./streamer/reducer";

const reducers = combineReducers({
  menu,
  settings,
  auth,
  authUser,
  streamer
});

export default reducers;
export { menu, settings, auth, authUser, streamer };
