import {
  SET_STREAMER_STATE_SUCCESS,
  SET_STREAMER_STATE_ERROR,
} from './../actions';

export const setStreamerStateSuccess = streamerState => ({
  type: SET_STREAMER_STATE_SUCCESS,
  payload: streamerState
});

export const setStreamerStateError = () => ({
  type: SET_STREAMER_STATE_ERROR,
});
