import { SET_STREAMER_STATE_ERROR, SET_STREAMER_STATE_SUCCESS } from '../actions';

const INIT_STATE = {
  games: [],
  bets: [],
  user: [],
  stats: null,
  transactions: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_STREAMER_STATE_SUCCESS:
      return { ...state, ...action.payload };
    case SET_STREAMER_STATE_ERROR:
      return { ...state, ...INIT_STATE };
    default:
      return { ...state };
  }
};
