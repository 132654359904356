import io from 'socket.io-client';
import { setStreamerStateSuccess } from '../redux/streamer/actions';
import { WS_URL } from '../constants/defaultValues';

export const initiateWss = (dispatch, userId) => {
  const socketConnection = io(WS_URL, {
    cors: { origins: ['http://localhost', 'https://streamer.thefundedbettor.com'] }
  });

  socketConnection.emit('Data', { userId });

  socketConnection.on('Data', data => {
    const { games, bets, user } = data[0];

    dispatch(
      setStreamerStateSuccess({
        games,
        bets,
        user: user[0],
        stats: user[0]?.bettingAccount?.stats,
        transactions: user[0].bettingAccountTransactions
      })
    );
  });

  socketConnection.on('disconnect', () => {
    console.log('Socket disconnected');
  });

  socketConnection.on('error', error => {
    console.log(error);
  });

  return socketConnection;
};
