import { NotificationManager } from './../components/common/react-notifications';
import { defaultDirection } from './../constants/defaultValues';

export const mapOrder = (array, order, key) => {
  array.sort(function(a, b) {
    var A = a[key],
      B = b[key];
    if (order.indexOf(A + '') > order.indexOf(B + '')) {
      return 1;
    } else {
      return -1;
    }
  });
  return array;
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; //January is 0!

  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  return dd + '.' + mm + '.' + yyyy;
};

export const getCurrentTime = () => {
  const now = new Date();
  return now.getHours() + ':' + now.getMinutes();
};

export const getDirection = () => {
  let direction = defaultDirection;
  if (localStorage.getItem('direction')) {
    const localValue = localStorage.getItem('direction');
    if (localValue === 'rtl' || localValue === 'ltr') {
      direction = localValue;
    }
  }
  return {
    direction,
    isRtl: direction === 'rtl'
  };
};

export const setDirection = localValue => {
  let direction = 'ltr';
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue;
  }
  localStorage.setItem('direction', direction);
};

export const successMessage = (notificationMessage, time) => {
  NotificationManager.primary(
    notificationMessage,
    'Success',
    time > 5000 ? time : 5000,
    null,
    null,
    'success filled'
  );
};

export const errorMessage = err => {
  const message = err instanceof Object ? err.error : err;
  NotificationManager.error(message, 'Error', 5000, null, null, 'error filled');
};

export const warningMessage = err => {
  const message = err instanceof Object ? err.warning : err;
  NotificationManager.warning(message, 'Warning', 5000, null, null, 'warning filled');
};

export const convertForReactSelect = (data, valueKey, labelKey) => {
  return data && data.length
    ? data.map(item => {
        item.value = item[valueKey];
        item.label = item[labelKey];
        return item;
      })
    : [];
};

export const getCookie = cookieName => {
  let name = cookieName + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
};

export const removeCookie = cookieName => {
  document.cookie = `${cookieName}=; path=/`;
};

export const getTimeRemaining = seconds => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

  return `${formattedMinutes}:${formattedSeconds}`;
};
