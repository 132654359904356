import React, { useEffect, Suspense, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import ColorSwitcher from './components/common/ColorSwitcher';
import NotificationContainer from './components/common/react-notifications/NotificationContainer';
import { isMultiColorActive } from './constants/defaultValues';
import { getDirection } from './helpers/Utils';
import useToken from './helpers/useToken';

import AppLocale from './lang';
import { initiateWss } from './wss';
import { setStreamerStateError } from './redux/streamer/actions';

const ViewApp = React.lazy(() => import('./views/app'));
const ViewUser = React.lazy(() => import('./views/user'));
const ViewError = React.lazy(() => import('./views/error'));
const ViewPublic = React.lazy(() => import('./views/public'));

const AuthenticatedApp = props => {
  const { token } = useToken();

  if (!token) {
    return <Redirect to={{ pathname: '/user/login', state: { from: props.location } }} />;
  }

  return <ViewApp {...props} />;
};

const App = () => {
  const dispatch = useDispatch();
  const [wssInitialized, setWssInitialized] = useState(false);
  const { locale, user } = useSelector(state => ({
    locale: state.settings.locale,
    user: state.auth.user
  }));
  const currentAppLocale = AppLocale[locale];

  useEffect(() => {
    let socketConnection;
    if (user && !wssInitialized) {
      socketConnection = initiateWss(dispatch, user.id);
    }

    return () => {
      if (socketConnection) {
        socketConnection.disconnect();
        dispatch(setStreamerStateError());
      }
    };
  }, [user]);

  useEffect(() => {
    const direction = getDirection();
    if (direction.isRtl) {
      document.body.classList.add('rtl');
      document.body.classList.remove('ltr');
    } else {
      document.body.classList.add('ltr');
      document.body.classList.remove('rtl');
    }
  }, []);

  return (
    <div>
      <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
        <React.Fragment>
          <NotificationContainer />
          {isMultiColorActive && <ColorSwitcher />}
          <Suspense fallback={<div className='loading' />}>
            <Router>
              <Switch>
                <Redirect exact path='/' to='/app' />
                <Route path='/app' render={props => <AuthenticatedApp {...props} />} />
                <Route path='/user' render={props => <ViewUser {...props} />} />
                <Route path='/error' exact render={props => <ViewError {...props} />} />
                <Route path='/public' exact render={props => <ViewPublic {...props} />} />
                <Redirect to='/error' />
              </Switch>
            </Router>
          </Suspense>
        </React.Fragment>
      </IntlProvider>
    </div>
  );
};

export default App;
